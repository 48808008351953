/**
 * OrderDetailPage
 * @module pages/OrderDetailPage
 */

import React, {
	useMemo,
	useContext,
	useState,
	useEffect,
	useRef,
	FC,
	forwardRef,
} from 'react';
import { LocalizationContext } from 'context/localization.context';
import {
	useTable,
	useSortBy,
	useRowSelect,
	HeaderProps,
	TableToggleCommonProps,
} from 'react-table';
import ContentArea from 'components/framework/ContentArea';
import SectionHeader from 'components/ui/SectionHeader';
import Breadcrumbs from 'components/framework/Breadcrumbs';
import {
	handleSortByKeyDown,
	handleKeyboardNavigation,
} from 'components/ui/Table';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart, selectCartObject } from 'store/modules/cart';
import Layout from 'components/ui/Layout';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { toast } from 'react-toastify';
import { OrderDetailsPageModel } from 'types/page-types';

interface IndeterminateCheckboxProps {
	indeterminate?: boolean;
}

const IndeterminateCheckbox = forwardRef<
	HTMLInputElement,
	IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef(null);
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		if (typeof resolvedRef === 'object' && resolvedRef.current) {
			resolvedRef.current.indeterminate = Boolean(indeterminate);
		}
	}, [resolvedRef, indeterminate]);

	return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const OrderDetailsPage: FC<OrderDetailsPageModel> = ({
	heading,
	text,
	backButton,
	externalId,
	lines,
	documents,
	showRemainingColumn,
	date,
	status,
	goodsReceiverName,
	customerRequisition,
	projectId,
	projectName,
	contentArea,
	isInEditMode,
	breadcrumbs,
	copyOrder,
}) => {
	const { t }: any = useContext(LocalizationContext);
	const firstRender = useRef(true);

	const data = useMemo(() => lines, [lines]);
	const [showOrderInformationModal, setShowOrderInformationModal] = useState(
		false
	);
	const [copyOrderError, setCopyOrderError] = useState<any>([]);
	const [copyingOrders, setCopyingOrders] = useState(false);
	const dispatch = useDispatch();

	const { cartLink, errorList, items } = useSelector(selectCartObject);

	const columns = useMemo(
		() => [
			{ Header: t('orderdetailspage/article'), accessor: 'itemId' },
			{ Header: t('orderdetailspage/description'), accessor: 'description' },
			{ Header: t('orderdetailspage/status'), accessor: 'status' },
			{ Header: t('orderdetailspage/deliverydate'), accessor: 'deliveryDate' },
			{ Header: t('orderdetailspage/quantity'), accessor: 'quantity' },
			...(showRemainingColumn
				? [{ Header: t('orderdetailspage/remaining'), accessor: 'remaining' }]
				: []),
			{ Header: t('orderdetailspage/currencylabel'), accessor: 'totalPrice' },
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		toggleAllRowsSelected,
	} = useTable(
		{
			columns,
			data,
		},
		useSortBy,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				...columns,
				{
					id: 'selection',
					Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<{}>) => (
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					),
					Cell: ({
						row,
					}: {
						row: { getToggleRowSelectedProps: () => TableToggleCommonProps };
					}) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
			]);
		}
	);

	useEffect(() => {
		toggleAllRowsSelected();
		//eslint-disable-next-line
	}, []);

	const orderInformation = useMemo(
		() => [
			{ field: t('orderdetailspage/ordernumber'), value: externalId },
			{
				field: t('orderdetailspage/customerrequisition'),
				value: customerRequisition,
			},
			{ field: t('orderdetailspage/deliverydate'), value: date },
			{ field: t('orderdetailspage/status'), value: status },
			{ field: t('orderdetailspage/projectnumber'), value: projectId },
			{ field: t('orderdetailspage/projectname'), value: projectName },
			{ field: t('orderdetailspage/goodsreceiver'), value: goodsReceiverName },
		],
		[
			customerRequisition,
			date,
			externalId,
			goodsReceiverName,
			projectId,
			projectName,
			status,
			t,
		]
	);

	const handleAddAllToCart = async () => {
		setCopyingOrders(true);

		try {
			let articleList: any = [];
			selectedFlatRows.forEach((item: any) => {
				articleList.push({
					itemCode: item.original.itemId,
					quantity: item.original.quantity,
					configId: item.original.configId,
					configDescription: item.original.configDescription,
				});
			});

			await dispatch(AddToCart('POST', articleList));
		} catch (err) {
			console.error(err);
		} finally {
			setCopyingOrders(false);
		}
	};

	useEffect(() => {
		if (
			errorList &&
			errorList.length > 0 &&
			errorList.find((eL: any) => eL.errorCode === 550)
		) {
			setShowOrderInformationModal(true);
			setCopyOrderError(errorList);
		}
	}, [errorList]);

	useEffect(() => {
		if (errorList?.length === 0 && !firstRender.current) {
			toast(t('savedcartpage/alladdedtocartmessage'), {
				type: toast.TYPE.SUCCESS,
			});
		}
		firstRender.current = false;
		//eslint-disable-next-line
	}, [items]);

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Layout backgroundColor="white">
					<Breadcrumbs links={breadcrumbs} />
				</Layout>
			)}
			<SectionHeader
				heading={heading}
				text={text}
				size={'large'}
				isInEditMode={isInEditMode}
			/>
			<Layout backgroundColor="white">
				<div className="md:max-w-2/3 max-w-full">
					{copyOrder && showOrderInformationModal && (
						<Modal
							title={copyOrder.modalHeading}
							heading={copyOrder.modalHeading}
							width="small"
							toggleModal={() => setShowOrderInformationModal(false)}
						>
							<p>{copyOrder.modalText}</p>
							<ul className="my-7">
								{copyOrderError.map((copyOrderErr: any, index: number) => {
									return <li key={index}>{copyOrderErr.message}</li>;
								})}
							</ul>
							<div className="flex justify-end space-x-5">
								<Button
									onClick={() => setShowOrderInformationModal(false)}
									buttonColor="grey"
								>
									{copyOrder.modalClose}
								</Button>
								<Button type="link" buttonColor="blue" url={cartLink.link}>
									{copyOrder.modalConfirmation}
								</Button>
							</div>
						</Modal>
					)}
					<table className="border-spacing-0 w-full">
						<caption className="sr-only border-0 overflow-hidden p-0 absolute w-1 h-1 -m-1">
							{t('orderdetailspage/orderinformationcaption')}
						</caption>

						<tbody>
							{orderInformation.map((info, index) => {
								const isFirst = index === 0;
								return (
									<tr
										className="odd:bg-white even:bg-greyLight text-2xl p-1"
										key={index}
									>
										<td className={`px-4 py-2 ${isFirst && 'font-medium'}`}>
											{info.field}
										</td>
										<td className={`px-4 py-2 ${isFirst && 'font-medium'}`}>
											{info.value}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="md:mt-10 mt-5 max-w-full pb-3 overflow-x-auto">
					<table
						className="border-spacing-0 w-full"
						{...getTableProps({ role: 'grid' })}
					>
						<caption className="sr-only border-0 overflow-hidden p-0 absolute w-1 h-1 -m-1">
							{t('orderdetailspage/orderrows')}
						</caption>

						<thead className="bg-blue">
							{headerGroups.map((headerGroup) => (
								<tr
									className="text-white text-2xl p-1"
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map((column, i) => (
										<th
											className={`px-4 py-2 text-2xl font-normal text-left whitespace-no-wrap`}
											{...column.getHeaderProps(
												column.getSortByToggleProps({
													title: `${t('shared/table/sortby')} ${column.Header}`,
												})
											)}
											aria-sort={
												column.isSorted && !column.isSortedDesc
													? t('shared/table/ascending')
													: t('shared/table/descending')
											}
											data-header-col={i}
											onKeyDown={(e) => {
												if (column.canSort) {
													return handleSortByKeyDown(
														e,
														column,
														i,
														rows.length,
														columns.length
													);
												}
											}}
										>
											{column.render('Header')}
											{column.id !== 'selection' && (
												<div className="relative w-2 inline-block ml-1">
													{column.isSorted && column.isSortedDesc && (
														<Icon icon="sortDescending" />
													)}
													{column.isSorted && !column.isSortedDesc && (
														<Icon icon="sortAscending" />
													)}
												</div>
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row);
								return (
									<tr
										className="odd:bg-white even:bg-greyLight text-2xl"
										{...row.getRowProps()}
									>
										{row.cells.map((cell, index) => {
											return (
												<td
													className="px-4 py-2"
													{...cell.getCellProps({
														//tabIndex: i === 0 && index === 0 ? 0 : -1,
													})}
													onKeyDown={(e) => {
														return handleKeyboardNavigation(
															e,
															rows.length,
															columns.length,
															i
														);
													}}
													data-row={i}
													data-col={index}
												>
													{(cell.column.id === 'itemId' ||
														cell.column.id === 'description') &&
													cell.value ? (
														<a
															href={`/article/${
																(row.original as { itemId: string }).itemId
															}`}
															target="self"
															rel="noopener noreferrer"
															className="text-blue"
														>
															{cell.value}
														</a>
													) : (
														cell.render('Cell')
													)}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					{copyOrder && selectedFlatRows.length > 0 && (
						<div className="mt-4 md:mt-10 -mb-2 flex justify-end">
							<Button
								type="button"
								buttonColor={'blue'}
								className=" ml-2 mt-2 md:mt-0 whitespace-no-wrap"
								onClick={handleAddAllToCart}
								disabled={copyingOrders}
							>
								{copyOrder.buttonText}
								<Icon icon="cart" size={1.25} aria-hidden="true" />
							</Button>
						</div>
					)}
				</div>
				<div className="md:mt-10 mt-5 mb-2">
					<table className="border-spacing-0 w-full">
						<caption className="sr-only border-0 overflow-hidden p-0 absolute w-1 h-1 -m-1">
							{t('orderdetailspage/documenttablecaption')}
						</caption>

						<thead className="bg-blue">
							<tr className="text-white text-2xl p-1">
								<th
									className={`px-4 py-2 text-2xl font-normal text-left whitespace-no-wrap`}
								>
									{t('orderdetailspage/document')}
								</th>
								<th
									className={`px-4 py-2 text-2xl font-normal text-left whitespace-no-wrap`}
								>
									{t('orderdetailspage/date')}
								</th>
							</tr>
						</thead>
						<tbody>
							{documents &&
								documents.map((doc: any, key: number) => {
									return (
										<tr
											key={key}
											className="odd:bg-white even:bg-greyLight text-2xl"
										>
											<td className="group flex space-x-2 items-center cursor-pointer px-4 py-2">
												<Icon icon="file" />

												<a
													className="group-hover:underline text-blue"
													href={doc.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													{doc.name}
												</a>
											</td>
											<td className="px-4 py-2">{doc.date}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
				{backButton && (
					<div className="text-right">
						<Button
							className="pl-0"
							type="link"
							url={`${backButton.link}?from=details`}
							buttonColor={'greyDarker'}
						>
							<Icon
								icon="chevron"
								direction="right"
								aria-hidden="true"
								className="mr-2"
							/>

							{backButton.text}
						</Button>
					</div>
				)}
			</Layout>
			<ContentArea contentArea={contentArea} />
		</main>
	);
};

export default OrderDetailsPage;
