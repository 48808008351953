/**
 * AddNotesModal
 * @module components/AddNotesModal
 */

import React, { useContext, useState } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Modal from 'components/ui/Modal';
import Button from '../Button';
import { useSelector } from 'react-redux';
import { selectCartObject } from 'store/modules/cart';

interface Props {
	/** Toggle modal callback */
	onToggle: any;

	handleAddNotes: any;

	goodsMarking?: string;

	comment?: string;

	commentHidden?: boolean;

	maxLengthLimits?: {
		articleGoodsMarkingLength: number;
		articleCommentsLength: number;
	};
}
const AddNotesModal = ({
	onToggle,
	handleAddNotes,
	goodsMarking: defaultGoodMarking,
	comment: defaultComment,
	commentHidden,
	maxLengthLimits,
}: Props) => {
	const { t }: any = useContext(LocalizationContext);
	const { goodsMarkingHidden } = useSelector(selectCartObject);
	const [goodsmarking, setGoodsmarking] = useState(defaultGoodMarking || '');
	const [comment, setComment] = useState(defaultComment || '');
	return (
		<Modal
			toggleModal={onToggle}
			heading={t('shared/articlerow/addanote')}
			title="Add notes modal"
			width="small"
		>
			{!goodsMarkingHidden ? (
				<div className="mb-5">
					<label className="block" htmlFor="goodsmarking">
						{t('shared/articlerow/goodsmarking')}
					</label>
					<textarea
						className="w-full h-25 p-2 border border-greyDark rounded-lg"
						id="goodsmarking"
						value={goodsmarking}
						onChange={(e) => setGoodsmarking(e.target.value)}
						maxLength={maxLengthLimits?.articleGoodsMarkingLength}
					></textarea>
				</div>
			) : (
				<></>
			)}

			{commentHidden ? (
				<></>
			) : (
				<div className="mb-5">
					<label className="block" htmlFor="comment">
						{t('shared/articlerow/comment')}
					</label>
					<textarea
						className="w-full h-25 p-2 border border-greyDark rounded-lg"
						id="comment"
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						maxLength={maxLengthLimits?.articleCommentsLength}
					/>
				</div>
			)}

			<div className="flex justify-end">
				<Button
					className="block m-0"
					type="button"
					buttonColor="blue"
					onClick={() => handleAddNotes(goodsmarking, comment)}
				>
					{t('shared/articlerow/savenote')}
				</Button>
			</div>
		</Modal>
	);
};

export default AddNotesModal;
