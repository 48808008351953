/**
 * CheckoutPage
 */

import Breadcrumbs from 'components/framework/Breadcrumbs';
import { Cell, Grid } from 'components/ui/Grid';
import Layout from 'components/ui/Layout';
import SectionHeader from 'components/ui/SectionHeader';
import Tooltip from 'components/ui/Tooltip';
import { LocalizationContext } from 'context/localization.context';
import { useContext, useEffect, useState } from 'react';

import { ArticleModel } from 'types/article';
import {
	AddNotesModel,
	ShipmentModel,
	DeliveryAddressModel,
	CheckoutReqestDataModel,
} from 'types/common';
import { CheckoutPageModel } from 'types/page-types';
import {
	CancelCartPriceError,
	CheckoutError,
	GenericWarning,
	ReloadCartPriceError,
	SaveCartError,
	ServerError,
} from 'common/helpers';
import clsx from 'clsx';
import CartArticleRow from 'components/framework/CartArticleRow';
import ExpansionPanel from 'components/ui/ExpansionPanel';
import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import Button from 'components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddNotes,
	cartUpdatePriceReloadCounter,
	Checkout,
	FetchCart,
	SaveCart,
	selectCartLoading,
	selectCartObject,
	selectCartPriceReloadCounter,
	UpdateCart,
} from 'store/modules/cart';
import { toast } from 'react-toastify';
import SaveCartModal from 'components/ui/SaveCartModal';
import ArticleDetail from 'components/common/ArticleDetail';
import AddGoodsmarkModal from 'components/ui/AddGoodsmarkModal';
import AlertModal from 'components/ui/AlertModal';
import axios, { AxiosResponse } from 'axios';

const isNewAddress = (address: DeliveryAddressModel) =>
	address &&
	(address.addressId === '00000000-0000-0000-0000-000000000000' || false);

const getDefaultSelectedAddress = (
	addressList: DeliveryAddressModel[] | undefined
) => {
	if (addressList && addressList.length > 0) {
		let address = addressList.find((a) => a.selected) as DeliveryAddressModel;
		if (isNewAddress(address)) {
			address = { ...address, name: '' };
		}
		return address;
	} else {
		return null;
	}
};

const CheckoutPage = ({
	breadcrumbs,
	framework,
	heading,
	text,
	desiredDateTooltip,
	leadTimeTooltip,
	disclaimerText,
	confirmButton,
	backToCartButton,
	saveCartButton,
}: CheckoutPageModel) => {
	const {
		withPrice,
		leadTime,
		desiredDateFormatted,
		customerRequisition,
		projectName,
		projectNumber,
		goodsReceptionContactName,
		goodsReceptionPhoneNumber,
		responsiblePerson,
		articleList,
		total,
		deliveryAddressList,
		deliveryMethod,
		goodsMarkingHidden,
		shipments,
		singleShipment,
		fieldRoles,
	} = useSelector(selectCartObject);
	const cartObject = useSelector(selectCartObject);
	const cartLoading = useSelector(selectCartLoading);
	const cartPriceReloadCounter = useSelector(selectCartPriceReloadCounter);
	const { t } = useContext<any>(LocalizationContext);
	const dispatch = useDispatch();
	const [showMore, setShowMore] = useState<boolean>(false);
	const [comment, setComment] = useState<string>('');
	const [agreementsConfirmed, setAgreementsConfirmed] = useState<boolean>(
		false
	);
	const [goodsmarkModalOpen, setGoodsmarkModalOpen] = useState(false);
	const [clearGoodsmarkModalOpen, setClearGoodsmarkModalOpen] = useState(false);
	const [checkoutErrorMessage, setCheckoutErrorMessage] = useState<string>('');
	const [saveCartModalOpen, setSaveCartModalOpen] = useState(false);
	const [overwriteCartModal, setOverwriteCartModal] = useState({
		isActive: false,
		cartName: '',
		message: '',
	});
	const [articleModal, setArticleModal] = useState({
		isActive: false,
		link: null,
	});

	const [reloadPricesModal, setReloadPricesModal] = useState({
		isActive: false,
		hasReloadButton: true,
		message: '',
	});

	// null: field untouched, "": toutched & valid, "error message": touched & invalid
	const [errorMessages, setErrorMessages] = useState<{
		[key: string]: string | null;
	}>({
		name: null,
		city: null,
		postalCode: null,
		street: null,
		goodsReceptionContactName: null,
		goodsReceptionPhoneNumber: null,
		projectName: null,
		projectNumber: null,
		comment: null,
		customerRequisition: null,
	});

	const [saveAddress, setSaveAddress] = useState<boolean>(false);

	const [
		selectedAddress,
		setSelectedAddress,
	] = useState<DeliveryAddressModel | null>(
		getDefaultSelectedAddress(deliveryAddressList)
	);

	const [requestData, setRequestData] = useState<CheckoutReqestDataModel>({
		address: {
			addressId: selectedAddress?.addressId || '',
			name: selectedAddress?.name || '',
			countryName: selectedAddress?.countryName || '',
			countryCode: selectedAddress?.countryCode || '',
			city: selectedAddress?.city || '',
			postalCode: selectedAddress?.postalCode || '',
			street: selectedAddress?.street || '',
			organizationId: selectedAddress?.organizationId || '',
			save: false,
		},
		goodsReceptionContactName: goodsReceptionContactName || '',
		goodsReceptionPhoneNumber: goodsReceptionPhoneNumber || '',
		responsiblePersonId:
			responsiblePerson?.find((x: any) => x.selected)?.id ||
			responsiblePerson?.[0]?.id ||
			'',
		customerRequisition: customerRequisition || '',
		projectName: projectName || '',
		projectNumber: projectNumber || '',
		comment: '',
	});

	const totalAmountOfArticles = articleList?.reduce(
		(total: any, next: ArticleModel) => {
			return total + next.quantity;
		},
		0
	);

	let totalAmountofShipmentArticles: number = 0;

	shipments?.forEach((shipment: ShipmentModel) => {
		shipment?.articleList?.forEach((article: ArticleModel) => {
			totalAmountofShipmentArticles += article.quantity;
		});
	});

	async function checkPostalCode(apiUrl: string): Promise<boolean | null> {
		try {
			const response: AxiosResponse<boolean> = await axios.post(apiUrl);

			// Assuming the API returns a well-structured response
			return response.data;
		} catch (error) {
			// Log or handle the error as needed
			console.error('Error fetching boolean data:', error);
			return null;
		}
	}

	const validateField = async (value: string | null, id: string) => {
		let message: string = '';
		const field = fieldRoles?.find(
			(field) => field.fieldName.toLowerCase() === id.toLowerCase()
		);
		const fieldType = field?.fieldType;
		const fieldMask = field?.fieldMask;

		const regex = fieldMask && new RegExp(fieldMask);

		if (id === 'postalCode') {
			if (fieldType === 'optionalapi') {
				const isValid: boolean | null = await checkPostalCode(
					`${field?.fieldMask}${value}`
				);

				if (!isValid && field?.message) {
					message = `optional${field?.message}`;
					return message;
				} else if (!isValid && !field?.message) {
					message = `optional${t('shared/form/optionalInvalidPostalCode')}`;
					return message;
				}
			} else if (fieldType === 'mandatoryapi') {
				const isValid: boolean | null = await checkPostalCode(
					`${field?.fieldMask}${value}`
				);

				if (!isValid && field?.message) {
					message = field?.message;
					return message;
				} else if (!isValid && !field?.message) {
					message = t('shared/form/mandatoryInvalidPostalCode');
					return message;
				}
			}
		}

		switch (id) {
			case 'name':
			case 'city':
			case 'goodsReceptionContactName':
				if (fieldType === 'mandatory') {
					// required field

					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}

				break;
			case 'goodsReceptionPhoneNumber':
				if (fieldType === 'mandatory') {
					// required field
					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}

				break;
			case 'projectName':
				if (fieldType === 'mandatory') {
					// required field
					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}

				break;
			case 'projectNumber':
				if (fieldType === 'mandatory') {
					// required field
					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}

				break;
			case 'customerRequisition':
				if (fieldType === 'mandatory') {
					// required field
					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}

				break;
			default:
				break;
		}

		return message;
	};

	const isInvalidField = (id: string) => {
		if (errorMessages[id] !== '' && errorMessages[id] !== null) {
			return true;
		}
	};

	const isValid = (errMsgs: { [key: string]: string | null }) => {
		return !Object.keys(errorMessages).some((key) => {
			// Do not block checkout if errorMessage is optional
			if (
				errMsgs[key] !== '' &&
				errMsgs[key] &&
				errMsgs[key]?.substring(0, 8) !== 'optional'
			) {
				return true;
			}
			return false;
		});
	};

	const handleAddressSelectChange = (e: any) => {
		let address = deliveryAddressList?.find(
			(a: DeliveryAddressModel) => a.addressId === e.target.value
		) as DeliveryAddressModel;

		if (
			selectedAddress === null ||
			address?.addressId !== selectedAddress?.addressId
		) {
			setErrorMessages({
				...errorMessages,
				name: null,
				city: null,
				postalCode: null,
				street: null,
			});

			if (isNewAddress(address)) {
				address = { ...address, name: '' };
			}
			setSelectedAddress(address);
			setRequestData({
				...requestData,
				address: {
					addressId: address.addressId,
					name: address.name,
					countryName: address.countryName,
					countryCode: address.countryCode,
					city: address.city,
					postalCode: address.postalCode,
					street: address.street,
					organizationId: address.organizationId,
					save: false,
				},
			});
		}
	};

	const handleSaveAddress = () => {
		setSaveAddress(!saveAddress);
		setRequestData({
			...requestData,
			address: { ...requestData.address, save: !saveAddress },
		});
	};

	const handleKeyDown = (e: any) => {
		if (e.target.type === 'checkbox' && e.keyCode === 13) {
			e.preventDefault();
			e.target.click();
		}
	};

	const handleOnChange = async (e: any) => {
		const { id, value } = e.target;

		let message = await validateField(value, id);

		setErrorMessages({ ...errorMessages, [id]: message });
	};

	const controlledTextArea = (e: any) => {
		let message: string = '';
		const { id, value } = e.target;
		const field = fieldRoles?.find(
			(field) => field.fieldName.toLowerCase() === id.toLowerCase()
		);
		const fieldType = field?.fieldType;
		const fieldMask = field?.fieldMask;
		const regex = fieldMask && new RegExp(fieldMask);
		if (id === 'comment') {
			const v = value.replace(/[\r\n\v]+/g, '');
			setComment(v);
		}
		if (fieldType !== undefined) {
			if (id === 'comment') {
				if (fieldType === 'mandatory') {
					// required field
					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}
			} else if (id === 'street') {
				if (fieldType === 'mandatory') {
					// required field

					if (value === '' || value === null || value === undefined) {
						message = t('shared/form/requiredfieldmessage');
					}
				}

				if (fieldMask) {
					// contains invalid characters
					if (value && regex && !regex.test(value)) {
						message = t('shared/form/invalidcharactersmessage');
					}
				}
			}
			return message;
		}
		setErrorMessages({ ...errorMessages, [id]: message });
	};

	const handleOnBlur = async (e: any) => {
		const { id, value } = e.target;
		let message = await validateField(value, id);
		setErrorMessages({ ...errorMessages, [id]: message });

		if (
			id === 'name' ||
			id === 'street' ||
			id === 'postalCode' ||
			id === 'city'
		) {
			setRequestData({
				...requestData,
				address: { ...requestData.address, [id]: value },
			});
		} else {
			setRequestData({ ...requestData, [id]: value });
		}
	};

	const handleCheckout = async () => {
		// Validate all fields before checkout
		let messages: { [key: string]: string | null } = { ...errorMessages };
		const validationPromises: Promise<void>[] = [];

		for (const key of Object.keys(messages).filter(
			(key) => messages[key] === null
		)) {
			const reqData = { ...requestData } as any;
			const value = reqData[key] || reqData.address[key];

			validationPromises.push(
				(async () => {
					if (value !== null) {
						messages[key] = await validateField(value, key);
					} else {
						messages[key] = null; // Or any default value for nullish cases
					}
				})()
			);
		}

		await Promise.all(validationPromises);
		setErrorMessages(messages);

		if (isValid(messages)) {
			try {
				const data = await dispatch(Checkout(requestData));
				window.location.href = data.url;
			} catch (err) {
				if (err instanceof CheckoutError) {
					setCheckoutErrorMessage(err.message);
				} else if (err instanceof ServerError) {
					setCheckoutErrorMessage(t('shared/toast/servererror'));
				} else if (err instanceof GenericWarning) {
					toast(err.message, { type: toast.TYPE.WARNING });
				}
			}
		} else {
			const firstErrorField = Object.keys(messages).find(
				(key) =>
					messages[key] !== '' &&
					messages[key] !== null &&
					messages[key]?.substring(0, 8) !== 'optional'
			);
			if (firstErrorField) document.getElementById(firstErrorField)?.focus();
		}
	};

	const handleSaveCart = async (cartName: string, shared: boolean) => {
		try {
			await dispatch(SaveCart(cartName, false, shared));
			toast(t('shared/toast/savecartsuccess'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			// SaveCartError - Cart already exists
			if (err instanceof SaveCartError) {
				setOverwriteCartModal({
					isActive: true,
					cartName,
					message: err.message,
				});
			} else if (err instanceof ServerError) {
				toast(t('shared/toast/savecarterror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			setSaveCartModalOpen(false);
		}
	};

	const handleOverwriteCart = async (shared: boolean) => {
		try {
			await dispatch(SaveCart(overwriteCartModal.cartName, true, shared));
			toast(t('shared/toast/savecartsuccess'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			if (err instanceof SaveCartError) {
				toast(err.message, { type: toast.TYPE.ERROR });
			}
		} finally {
			setOverwriteCartModal({ ...overwriteCartModal, isActive: false });
		}
	};
	const hasArticles = articleList && articleList.length > 0;

	const getInitialCheckedBoxes = () => {
		let initialArticleList: any = [];
		!goodsMarkingHidden &&
			shipments &&
			shipments.length > 0 &&
			shipments?.forEach((shipment: ShipmentModel) => {
				shipment?.articleList?.forEach((articleItem: ArticleModel) => {
					initialArticleList.push({
						itemCode: articleItem?.itemCode,
						lineItemId: articleItem?.lineItemId,
						comments: articleItem?.notes?.comments || '',
						selected: false,
					});
				});
			});
		!goodsMarkingHidden &&
			articleList &&
			articleList.length > 0 &&
			articleList?.forEach((item: ArticleModel) => {
				initialArticleList.push({
					itemCode: item?.itemCode,
					lineItemId: item?.lineItemId,
					comments: item?.notes?.comments || '',
					selected: false,
				});
			});
		return initialArticleList;
	};

	interface checkbox {
		itemCode: string;
		lineItemId: number;
		comments: string;
		selected: boolean;
	}

	const [toolbarActive, setToolbarActive] = useState(false);
	const [checkedBoxes, setCheckedBoxes] = useState<checkbox[]>(
		getInitialCheckedBoxes()
	);

	const isAllChecked = () => {
		for (let index in checkedBoxes) {
			if (!checkedBoxes[index].selected) {
				return false;
			}
		}
		return true;
	};

	const isAtLeastOneChecked = () => {
		for (let index in checkedBoxes) {
			if (checkedBoxes[index].selected) {
				return true;
			}
		}
		return false;
	};

	const handleCheckSingle = (itemCode: string, lineItemId: number) => {
		let newCheckedBoxes: any = [...checkedBoxes];

		for (let index in checkedBoxes) {
			if (
				checkedBoxes[index].itemCode === itemCode &&
				checkedBoxes[index].lineItemId === lineItemId
			) {
				newCheckedBoxes[index].selected = !checkedBoxes[index].selected;
				setCheckedBoxes(newCheckedBoxes);
				setToolbarActive(isAtLeastOneChecked());
			}
		}
	};

	const handleCheckAll = () => {
		let newCheckedBoxes: any = [];

		if (isAllChecked()) {
			setCheckedBoxes(getInitialCheckedBoxes());
			setToolbarActive(false);
		} else {
			articleList &&
				articleList.length > 0 &&
				articleList?.forEach((item: ArticleModel) => {
					newCheckedBoxes.push({
						itemCode: item.itemCode,
						lineItemId: item.lineItemId,
						comments: item.notes.comments || '',
						selected: true,
					});
				});
			shipments &&
				shipments.length > 0 &&
				shipments?.forEach((shipment: ShipmentModel) => {
					shipment?.articleList?.forEach((item: ArticleModel) => {
						newCheckedBoxes.push({
							itemCode: item.itemCode,
							lineItemId: item.lineItemId,
							comments: item.notes.comments || '',
							selected: true,
						});
					});
				});
			setCheckedBoxes(newCheckedBoxes);
			setToolbarActive(true);
		}
	};

	const getCheckboxIndex = (shipmentIndex: number, articleIndex: number) => {
		let index = 0;
		if (shipments && shipments.length > 0) {
			for (let i = 0; i < shipmentIndex; i++) {
				index += shipments[i].articleList.length;
			}
		}
		return index + articleIndex;
	};

	const handleSelectedGoodsmark = async (goodsmarking?: string) => {
		try {
			let notesArray: AddNotesModel[] = [];
			checkedBoxes?.forEach((item) => {
				if (item.selected) {
					notesArray.push({
						itemCode: item.itemCode,
						lineItemId: item.lineItemId,
						comments: item.comments,
						goodsMarking: goodsmarking || '',
					});
				}
			});
			await dispatch(AddNotes(notesArray));
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/cartaddnoteserror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		} finally {
			if (goodsmarkModalOpen) {
				setGoodsmarkModalOpen(false);
			} else if (clearGoodsmarkModalOpen) {
				setClearGoodsmarkModalOpen(false);
			}
			setCheckedBoxes(getInitialCheckedBoxes());
			setToolbarActive(false);
		}
	};

	const handleReloadPrices = async () => {
		try {
			await dispatch(cartUpdatePriceReloadCounter(cartPriceReloadCounter + 1));
			fetchCartObject();
		} catch (err) {
		} finally {
			setReloadPricesModal({ ...reloadPricesModal, isActive: false });
		}
	};

	const fetchCartObject = async () => {
		try {
			await dispatch(FetchCart());
		} catch (err) {
			if (err instanceof ServerError) {
				toast(t('shared/toast/servererror'), { type: toast.TYPE.ERROR });
			} else if (err instanceof ReloadCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: true,
					message: err.message,
				});
			} else if (err instanceof CancelCartPriceError) {
				setReloadPricesModal({
					isActive: true,
					hasReloadButton: false,
					message: err.message,
				});
			} else if (err instanceof GenericWarning) {
				toast(err.message, { type: toast.TYPE.WARNING });
			}
		}
	};

	useEffect(() => {
		if (withPrice === false) {
			fetchCartObject();
		}
		//eslint-disable-next-line

		let items: any = [];

		cartObject.articleList?.map((art) => {
			return items.push({
				item_name: art.itemName,
				item_id: art.itemCode,
				price: art.prices.grossPriceFormatted,
				item_variant: art.attributeName,
				quantity: art.quantity,
			});
		});

		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'begin_checkout',
				domain: framework.header.activeMarket,
				ecommerce: {
					items: items,
				},
			});
		}
		//eslint-disable-next-line
	}, [cartObject]);

	useEffect(() => {
		setCheckedBoxes(getInitialCheckedBoxes());
		setToolbarActive(false);
		//eslint-disable-next-line
	}, [articleList]);

	return (
		<>
			<main id="main-content">
				<Layout backgroundColor="white">
					{breadcrumbs && <Breadcrumbs links={breadcrumbs} className="" />}
					<Grid className="checkout-grid" padding={false} margin={false}>
						<Cell span={12} className="lg:pr-3/12">
							<SectionHeader heading={heading} text={text} size={'large'} />
						</Cell>
						<Cell span={12}>
							<h2 className="text-h4 text-blueDark font-light mb-4">
								{deliveryMethod === 'delivery' &&
									t('checkoutpage/deliverydetails')}
								{deliveryMethod === 'pickup' && t('checkoutpage/pickupdetails')}
							</h2>
						</Cell>
						<Cell span={12} desktop={6} className="lg:pr-2/12">
							{deliveryMethod === 'pickup' &&
								deliveryAddressList &&
								deliveryAddressList.length > 0 && (
									<>
										<h3 className="text-h6 font-bold text-blue mb-3">
											{t('checkoutpage/pickupadress')}
										</h3>
										<div>
											<p className="text-p">{deliveryAddressList[0].name}</p>
											<p className="text-p">{deliveryAddressList[0].street}</p>
											<p className="text-p">
												{deliveryAddressList[0].postalCode}{' '}
												{deliveryAddressList[0].city}
											</p>
											<p className="text-p">
												{deliveryAddressList[0].countryName}
											</p>
										</div>
									</>
								)}
							{deliveryMethod === 'delivery' &&
								deliveryAddressList &&
								deliveryAddressList.length > 0 && (
									<form>
										<div className="mb-16 addresspicker">
											<label
												className="block text-h6 font-bold text-blue mb-2"
												htmlFor={'addressId'}
											>
												{t('checkoutpage/deliveryadress')}
											</label>

											<select
												id={'addressId'}
												name={'addressId'}
												className="font-standard text-p px-4 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
												onChange={handleAddressSelectChange}
												placeholder={t('checkoutpage/chooseaddress')}
												value={selectedAddress ? selectedAddress.addressId : ''}
												required
											>
												<option value="" disabled hidden>
													{t('checkoutpage/chooseaddress')}
												</option>
												{deliveryAddressList?.map(
													(address: DeliveryAddressModel, index: number) => (
														<option key={index} value={address.addressId}>
															{address.name}
														</option>
													)
												)}
											</select>

											{selectedAddress && (
												<div key={selectedAddress.addressId}>
													<div className="validation-required my-6">
														<label
															className="block text-h6 font-bold text-blue mb-2"
															htmlFor={'name'}
														>
															{t(`checkoutpage/deliveryaddressname`)}
														</label>
														<input
															type="text"
															id={'name'}
															name={'name'}
															defaultValue={selectedAddress.name}
															placeholder={t(
																'checkoutpage/deliveryaddressnameplaceholder'
															)}
															readOnly={!selectedAddress.isEditable}
															onChange={handleOnChange}
															onBlur={handleOnBlur}
															className={clsx(
																isInvalidField('name')
																	? 'border-red'
																	: 'border-greyDark',
																'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
															)}
															required
														/>
														<span
															className="text-red text-p"
															aria-live="polite"
														>
															{errorMessages['name']}
														</span>
													</div>
													<div className="my-6">
														<label
															className="block text-h6 font-bold text-blue mb-2"
															htmlFor={'street'}
														>
															{t(`checkoutpage/deliveryaddress`)}
														</label>
														<textarea
															id={'street'}
															name={'street'}
															defaultValue={selectedAddress.street}
															placeholder={t(
																'checkoutpage/deliveryaddressplaceholder'
															)}
															readOnly={!selectedAddress.isEditable}
															onChange={controlledTextArea}
															onBlur={handleOnBlur}
															className={clsx(
																isInvalidField('street')
																	? 'border-red'
																	: 'border-greyDark',
																'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
															)}
															required
														/>
														<span
															className="text-red text-p"
															aria-live="polite"
														>
															{errorMessages['street']}
														</span>
													</div>
													<div className="my-6 flex justify-between">
														<div className="validation-required md:w-4/12">
															<label
																className="block text-h6 font-bold text-blue mb-2"
																htmlFor={'postalCode'}
															>
																{t(`checkoutpage/deliveryaddresspostalcode`)}
															</label>
															<input
																id={'postalCode'}
																name={'postalCode'}
																defaultValue={selectedAddress.postalCode}
																placeholder={t(
																	'checkoutpage/deliveryaddresspostalcodeplaceholder'
																)}
																readOnly={!selectedAddress.isEditable}
																onChange={handleOnChange}
																onBlur={handleOnBlur}
																className={clsx(
																	isInvalidField('postalCode')
																		? 'border-red'
																		: 'border-greyDark',
																	'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
																)}
																required
															/>
															<span
																className="text-red text-p"
																aria-live="polite"
															>
																{errorMessages['postalCode']?.startsWith(
																	'optional'
																) &&
																	errorMessages['postalCode'].slice(8).trim()}
															</span>
														</div>
														<div className="validation-required md:w-7/12">
															<label
																className="block text-h6 font-bold text-blue mb-2"
																htmlFor={'city'}
															>
																{t(`checkoutpage/deliveryaddresscity`)}
															</label>
															<input
																id={'city'}
																name={'city'}
																defaultValue={selectedAddress.city}
																placeholder={t(
																	'checkoutpage/deliveryaddresscityplaceholder'
																)}
																readOnly={!selectedAddress.isEditable}
																onChange={handleOnChange}
																onBlur={handleOnBlur}
																className={clsx(
																	isInvalidField('city')
																		? 'border-red'
																		: 'border-greyDark',
																	'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
																)}
																required
															/>
															<span
																className="text-red text-p"
																aria-live="polite"
															>
																{errorMessages['city']}
															</span>
														</div>
													</div>
													<div className="my-6">
														<label
															className="block text-h6 font-bold text-blue mb-2"
															htmlFor={'countryName'}
														>
															{t(`checkoutpage/deliveryaddresscountry`)}
														</label>
														<input
															id={'countryName'}
															name={'countryName'}
															defaultValue={selectedAddress.countryName}
															readOnly={true}
															className="font-standard text-p px-4 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
														/>
													</div>
													{selectedAddress.isEditable && (
														<label className="flex items-baseline cursor-pointer relative">
															<input
																type="checkbox"
																id="saveSelectedAddress"
																className="relative opacity-0"
																onChange={handleSaveAddress}
																onKeyDown={handleKeyDown}
															/>
															<Icon
																icon={saveAddress ? 'checkSquare' : 'square'}
																size={1.25}
																className={clsx(
																	'absolute left-0 focused-sibling:outline-black ',
																	saveAddress ? 'text-blue' : 'text-greyDarkest'
																)}
																aria-hidden={true}
															/>
															<span className="ml-4 text-p">
																{t('checkoutpage/saveaddress')}
															</span>
														</label>
													)}
												</div>
											)}
										</div>
									</form>
								)}
						</Cell>
						<Cell span={12} desktop={6} className="lg:pr-2/12">
							{deliveryMethod === 'delivery' && leadTime && (
								<>
									<h3 className="text-h6 font-bold text-blue mb-3">
										{t('checkoutpage/estimatedleadtime')}
										{leadTimeTooltip && (
											<Tooltip
												title={t('checkoutpage/descriptionofestimatedleadtime')}
											>
												{leadTimeTooltip}
											</Tooltip>
										)}
									</h3>

									<p className="text-p">{`${leadTime} ${t(
										'checkoutpage/businessdaysafterorderconfirmation'
									)}`}</p>
								</>
							)}
							<h3 className="text-h6 font-bold text-blue mt-6 mb-3">
								{deliveryMethod === 'delivery' &&
									t('checkoutpage/desireddeliverydate')}
								{deliveryMethod === 'pickup' &&
									t('checkoutpage/desiredpickupdate')}
								{desiredDateTooltip && (
									<Tooltip
										title={t('checkoutpage/descriptionofdesireddeliverydate')}
									>
										{desiredDateTooltip}
									</Tooltip>
								)}
							</h3>
							<p className="text-p">{desiredDateFormatted}</p>
						</Cell>
						<Cell span={12} className="lg:pr-2/12">
							<h2 className="text-h4 text-blueDark font-light mb-4 mt-12">
								{t('checkoutpage/goodsreceptionandreferences')}
							</h2>
						</Cell>
						<Cell span={12} desktop={6} className="lg:pr-2/12">
							<div
								className={clsx(
									fieldRoles?.find(
										(field) =>
											field.fieldName.toLowerCase() ===
											'goodsreceptioncontactname'
									)?.fieldType === 'mandatory'
										? 'validation-required'
										: '',
									'mb-6'
								)}
							>
								<label
									htmlFor={'goodsReceptionContactName'}
									className="block text-h6 font-bold text-blue mb-2"
								>
									{t('checkoutpage/goodsreceptioncontactname')}
								</label>
								<input
									id={'goodsReceptionContactName'}
									name={'goodsReceptionContactName'}
									defaultValue={goodsReceptionContactName}
									placeholder={t(
										'checkoutpage/goodsreceptioncontactnameplaceholder'
									)}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									className={clsx(
										isInvalidField('goodsReceptionContactName')
											? 'border-red'
											: 'border-greyDark',
										'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
									)}
									required={
										fieldRoles?.find(
											(field) =>
												field.fieldName.toLowerCase() ===
												'goodsreceptioncontactname'
										)?.fieldType === 'mandatory'
											? true
											: false
									}
								/>
								<span className="text-red text-p" aria-live="polite">
									{errorMessages['goodsReceptionContactName']}
								</span>
							</div>
							<div
								className={clsx(
									fieldRoles?.find(
										(field) =>
											field.fieldName.toLowerCase() ===
											'goodsreceptionphonenumber'
									)?.fieldType === 'mandatory'
										? 'validation-required'
										: '',
									'my-6'
								)}
							>
								<label
									htmlFor={'goodsReceptionPhoneNumber'}
									className="block text-h6 font-bold text-blue mb-2"
								>
									{t('checkoutpage/goodsreceptionphonenumber')}
								</label>

								<input
									required={
										fieldRoles?.find(
											(field) =>
												field.fieldName.toLowerCase() ===
												'goodsreceptionphonenumber'
										)?.fieldType === 'mandatory'
											? true
											: false
									}
									id={'goodsReceptionPhoneNumber'}
									name={'goodsReceptionPhoneNumber'}
									defaultValue={goodsReceptionPhoneNumber}
									placeholder={t(
										'checkoutpage/goodsreceptionphonenumberplaceholder'
									)}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									className={clsx(
										isInvalidField('goodsReceptionPhoneNumber')
											? 'border-red'
											: 'border-greyDark',
										'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
									)}
								/>
								<span className="text-red text-p" aria-live="polite">
									{errorMessages['goodsReceptionPhoneNumber']}
								</span>
							</div>

							{responsiblePerson && responsiblePerson.length > 0 && (
								<div className="my-6">
									<label
										htmlFor={'responsiblePersonId'}
										className="block text-h6 font-bold text-blue mb-2"
									>
										{t('checkoutpage/responsiblefordelivery')}
									</label>
									<select
										id={'responsiblePersonId'}
										name="responsiblePersonId"
										className="font-standard text-p px-4 py-2 border rounded border-greyDark w-full placeholder-greyDarkest"
										defaultValue={
											responsiblePerson?.find((p: any) => p.selected)?.id || ''
										}
										onBlur={handleOnBlur}
										required
									>
										{responsiblePerson?.map((person: any, index: number) => (
											<option key={index} value={person.id}>
												{person.name}
											</option>
										))}
									</select>
								</div>
							)}

							<div className="my-6">
								<label
									htmlFor={'comment'}
									className="block text-h6 font-bold text-blue mb-2"
								>
									{t('checkoutpage/additionalcomments')}
								</label>
								<textarea
									className={clsx(
										isInvalidField('comment')
											? 'border-red'
											: 'border-greyDark',
										'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
									)}
									id={'comment'}
									name={'comment'}
									placeholder={t('checkoutpage/additionalcommentsplaceholder')}
									onBlur={handleOnBlur}
									onChange={controlledTextArea}
									value={comment}
								></textarea>
								<span className="text-red text-p" aria-live="polite">
									{errorMessages['comment']}
								</span>
							</div>
						</Cell>
						<Cell span={12} desktop={6} className="lg:pr-2/12">
							<div
								className={clsx(
									!projectName &&
										fieldRoles?.find(
											(field) => field.fieldName.toLowerCase() === 'projectname'
										)?.fieldType === 'mandatory'
										? 'validation-required'
										: '',
									'my-6'
								)}
							>
								{projectName ? (
									<>
										<h3 className="block text-h6 font-bold text-blue mb-2">
											{t('checkoutpage/projectname')}
										</h3>
										<p className="text-p">{projectName}</p>
									</>
								) : (
									<>
										<label
											htmlFor={'projectName'}
											className="block text-h6 font-bold text-blue mb-2"
										>
											{t('checkoutpage/projectname')}
										</label>
										<input
											required={
												fieldRoles?.find(
													(field) =>
														field.fieldName.toLowerCase() === 'projectname'
												)?.fieldType === 'mandatory'
													? true
													: false
											}
											type="text"
											id={'projectName'}
											name={'projectName'}
											placeholder={t('checkoutpage/projectnameplaceholder')}
											className={clsx(
												isInvalidField('projectName')
													? 'border-red'
													: 'border-greyDark',
												'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
											)}
											onChange={handleOnChange}
											onBlur={handleOnBlur}
										/>
										<span className="text-red text-p" aria-live="polite">
											{errorMessages['projectName']}
										</span>
									</>
								)}
							</div>

							{fieldRoles
								?.find(
									(field) => field.fieldName.toLowerCase() === 'projectnumber'
								)
								?.fieldType.toLowerCase() !== 'hidden' && (
								<div
									className={clsx(
										!projectNumber &&
											fieldRoles?.find(
												(field) =>
													field.fieldName.toLowerCase() === 'projectnumber'
											)?.fieldType === 'mandatory'
											? 'validation-required'
											: '',
										'my-6'
									)}
								>
									{projectNumber ? (
										<>
											<h3 className="block text-h6 font-bold text-blue mb-2">
												{t('checkoutpage/projectnumber')}
											</h3>
											<p className="text-p">{projectNumber}</p>
										</>
									) : (
										<>
											<label
												htmlFor={'projectNumber'}
												className="block text-h6 font-bold text-blue mb-2"
											>
												{t('checkoutpage/projectnumber')}
											</label>
											<input
												required={
													fieldRoles?.find(
														(field) =>
															field.fieldName.toLowerCase() === 'projectnumber'
													)?.fieldType === 'mandatory'
														? true
														: false
												}
												type="text"
												id={'projectNumber'}
												name={'projectNumber'}
												placeholder={t('checkoutpage/projectnumberplaceholder')}
												className={clsx(
													isInvalidField('projectNumber')
														? 'border-red'
														: 'border-greyDark',
													'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
												)}
												onChange={handleOnChange}
												onBlur={handleOnBlur}
												maxLength={20}
											/>
											<span className="text-red text-p" aria-live="polite">
												{errorMessages['projectNumber']}
											</span>
										</>
									)}
								</div>
							)}
							<div
								className={clsx(
									fieldRoles?.find(
										(field) =>
											field.fieldName.toLowerCase() === 'customerrequisition'
									)?.fieldType === 'mandatory'
										? 'validation-required'
										: '',
									'my-6'
								)}
							>
								<label
									htmlFor={'customerRequisition'}
									className="block text-h6 font-bold text-blue mb-2"
								>
									{t('checkoutpage/customerrequisition')}
								</label>
								<input
									required={
										fieldRoles?.find(
											(field) =>
												field.fieldName.toLowerCase() === 'customerrequisition'
										)?.fieldType === 'mandatory'
											? true
											: false
									}
									type="text"
									id={'customerRequisition'}
									name={'customerRequisition'}
									defaultValue={customerRequisition}
									placeholder={t('checkoutpage/customerrequisitionplaceholder')}
									className={clsx(
										isInvalidField('customerRequisition')
											? 'border-red'
											: 'border-greyDark',
										'font-standard text-p px-4 py-2 border rounded w-full placeholder-greyDarkest'
									)}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									maxLength={30}
								/>
								<span className="text-red text-p" aria-live="polite">
									{errorMessages['customerRequisition']}
								</span>
							</div>
						</Cell>
						<Cell span={12} className="mt-8">
							<h2 className="text-h3 text-blue font-medium mb-4">
								{t('checkoutpage/articlelist')}
							</h2>
							{articleList && articleList.length > 0 && (
								<p className="text-h4 text-black font-medium mb-3">
									{`${t('checkoutpage/total')} ${totalAmountOfArticles} ${t(
										'checkoutpage/itemsincart'
									)}`}
								</p>
							)}{' '}
							{shipments && shipments.length > 0 && (
								<p className="text-h4 text-black font-medium mb-3">
									{`${t(
										'checkoutpage/total'
									)} ${totalAmountofShipmentArticles} ${t(
										'checkoutpage/itemsincart'
									)}`}
								</p>
							)}
						</Cell>
						{shipments && shipments.length > 0 && (
							<>
								<Cell span={12}>
									<div className="block md:flex flex-row-reverse justify-between md:items-end lg:items-center">
										<label className="flex items-baseline cursor-pointer relative">
											<span className="text-p flex w-full justify-end mr-5">
												{t('checkoutpage/allowsplitdeliveries')}
											</span>
											<input
												type="checkbox"
												id="singleShipment"
												className="relative opacity-0"
												checked={singleShipment}
												onChange={(event) => {
													dispatch(
														UpdateCart({
															singleShipment: event.target.checked,
														})
													);
												}}
												onKeyDown={handleKeyDown}
											/>
											<Icon
												icon={singleShipment ? 'square' : 'checkSquare'}
												size={1.25}
												className={clsx(
													'absolute right-0 focused-sibling:outline-black',
													singleShipment ? 'text-greyDarkest' : 'text-blue'
												)}
												aria-hidden={true}
											/>
										</label>
										{shipments?.some(
											(shipment: ShipmentModel) => shipment.shipDateChanged
										) &&
											!cartLoading && (
												<p className="px-4 py-1 flex items-baseline bg-orangeLightest rounded-xl md:w-6/12 mt-4 md:mt-0">
													<span className="mr-2">
														<Icon
															icon="alertTriangle"
															color="#D0021B"
															className="text-orangeLight"
															size={1}
														/>
													</span>
													<span className="text-p text-orangeLight">
														{t(
															'checkoutpage/desireddateandshipdateisnotmatched'
														)}
													</span>
												</p>
											)}
									</div>
								</Cell>
							</>
						)}
						<Cell span={12} className="mt-4">
							{articleList && articleList.length > 0 && (
								<div className="relative">
									<div
										className={clsx(
											articleList.length < 4 || showMore
												? 'h-full'
												: 'h-140 overflow-hidden'
										)}
									>
										{!goodsMarkingHidden && (
											<div className="md:px-4 py-4 flex border-b border-t border-greyDarker">
												<label htmlFor="select-all-articles" className="flex">
													<input
														id="select-all-articles"
														type="checkbox"
														className="w-4.5 h-4.5"
														checked={isAllChecked() || false}
														onClick={() => handleCheckAll()}
													/>
													<span className="text-p align-top ml-2">
														{t('shared/goodsmark/selectallrows')}
													</span>
												</label>
												<div className="md:flex ml-4 md:ml-0">
													<p
														className={clsx(
															!toolbarActive && 'hidden',
															'text-p flex md:ml-6 md:pl-6 mb-1 md:mb-0 md:border-l md:border-black'
														)}
													>
														<span>
															<Icon
																icon="edit"
																className="mr-2"
																color="#2A2A2A"
																size={1.25}
															/>
														</span>
														<button
															onClick={(e) => {
																e.preventDefault();
																setGoodsmarkModalOpen(true);
															}}
															className="text-blue hover:underline"
														>
															{t('shared/goodsmark/editgoodsmark')}
														</button>
													</p>
													<p
														className={clsx(
															!toolbarActive && 'hidden',
															'text-p flex md:ml-6 md:pl-6 md:border-l md:border-black'
														)}
													>
														<span>
															<Icon
																icon="trash"
																className="mr-2"
																color="#2A2A2A"
																size={1.25}
															/>
														</span>
														<button
															onClick={(e) => {
																e.preventDefault();
																setClearGoodsmarkModalOpen(true);
															}}
															className="text-blue hover:underline"
														>
															{t('shared/goodsmark/deletegoodsmark')}
														</button>
													</p>
												</div>
											</div>
										)}
										{articleList?.map((item: ArticleModel, index: number) => {
											return (
												<CartArticleRow
													key={index}
													addToCartBtnDisabled={true}
													handleOpenArticleModal={setArticleModal}
													handleCheck={handleCheckSingle}
													checked={checkedBoxes[index]?.selected}
													cartLoading={cartLoading}
													certificates={item?.certificates}
													{...item}
													firstItem={index === 0}
													activeMarket={framework.header.activeMarket}
													language={framework.language}
												/>
											);
										})}
									</div>
									{articleList.length > 3 && (
										<>
											<div
												className={clsx(
													showMore ? 'hidden' : 'block',
													'w-full h-25 absolute bottom-0 showmore-transparent-block'
												)}
											/>
											<ExpansionPanel
												toggleExpansionPanel={() => setShowMore(!showMore)}
												expanded={showMore}
												className=""
											/>
										</>
									)}
								</div>
							)}

							{cartLoading && articleList?.length === 0 ? (
								<>
									<div className="flex justify-center w-full">
										<Icon
											animate="spin"
											size={5}
											aria-hidden={true}
											icon="loader"
										/>
									</div>
									<div className="flex justify-center px-6 mt-2">
										<p className="px-4 py-1 flex items-baseline bg-orangeLightest rounded-xl md:w-6/12 mb-4 md:mb-0">
											<span className="mr-2">
												<Icon
													icon="alertTriangle"
													color="#D0021B"
													className="text-orangeLight"
													size={1}
												/>
											</span>
											<span className="text-p text-orangeLight">
												{t('checkoutpage/waitingforarticlelists')}
											</span>
										</p>
									</div>
								</>
							) : (
								shipments &&
								shipments.length > 0 && (
									<div className="relative">
										{!goodsMarkingHidden && (
											<div className="md:px-4 py-4 flex border-b border-t border-greyDarker">
												<label htmlFor="select-all-articles" className="flex">
													<input
														id="select-all-articles"
														type="checkbox"
														className="w-4.5 h-4.5"
														checked={isAllChecked() || false}
														onClick={() => handleCheckAll()}
													/>
													<span className="text-p align-top ml-2">
														{t('shared/goodsmark/selectallrows')}
													</span>
												</label>
												<div className="md:flex ml-4 md:ml-0">
													<p
														className={clsx(
															!toolbarActive && 'hidden',
															'text-p flex md:ml-6 md:pl-6 mb-1 md:mb-0 md:border-l md:border-black'
														)}
													>
														<span>
															<Icon
																icon="edit"
																className="mr-2"
																color="#2A2A2A"
																size={1.25}
															/>
														</span>
														<button
															onClick={(e) => {
																e.preventDefault();
																setGoodsmarkModalOpen(true);
															}}
															className="text-blue hover:underline"
														>
															{t('shared/goodsmark/editgoodsmark')}
														</button>
													</p>
													<p
														className={clsx(
															!toolbarActive && 'hidden',
															'text-p flex md:ml-6 md:pl-6 md:border-l md:border-black'
														)}
													>
														<span>
															<Icon
																icon="trash"
																className="mr-2"
																color="#2A2A2A"
																size={1.25}
															/>
														</span>
														<button
															onClick={(e) => {
																e.preventDefault();
																setClearGoodsmarkModalOpen(true);
															}}
															className="text-blue hover:underline"
														>
															{t('shared/goodsmark/deletegoodsmark')}
														</button>
													</p>
												</div>
											</div>
										)}
										{shipments?.map(
											(shipmentItem: ShipmentModel, shipmentIndex: number) => {
												return (
													<div
														className={clsx(
															shipmentItem?.articleList?.length < 4 || showMore
																? 'h-full'
																: 'h-140 overflow-hidden'
														)}
														key={shipmentIndex}
													>
														<div className="clear-both bg-blue flex justify-between font-normal text-white p-1 md:p-2">
															<span className="text-p align-center ml-2">
																{`${t('checkoutpage/shipdate')} ${
																	shipmentItem?.shipDateFormatted
																}`}
															</span>
														</div>

														{shipmentItem?.articleList?.map(
															(item: ArticleModel, articleIndex: number) => {
																return (
																	<CartArticleRow
																		key={articleIndex}
																		addToCartBtnDisabled={true}
																		handleOpenArticleModal={setArticleModal}
																		handleCheck={handleCheckSingle}
																		checked={
																			checkedBoxes[
																				getCheckboxIndex(
																					shipmentIndex,
																					articleIndex
																				)
																			]?.selected
																		}
																		cartLoading={cartLoading}
																		{...item}
																		firstItem={articleIndex === 0}
																		certificates={item?.certificates}
																		shipDateChanged={item?.shipDateChanged}
																		activeMarket={framework.header.activeMarket}
																		language={framework.language}
																	/>
																);
															}
														)}
													</div>
												);
											}
										)}
										{shipments?.map((shipmentItem: ShipmentModel) => {
											return (
												shipmentItem.articleList.length > 3 && (
													<>
														<div
															className={clsx(
																showMore ? 'hidden' : 'block',
																'w-full h-25 absolute bottom-0 showmore-transparent-block'
															)}
														/>
														<ExpansionPanel
															toggleExpansionPanel={() =>
																setShowMore(!showMore)
															}
															expanded={showMore}
															className=""
														/>
													</>
												)
											);
										})}
									</div>
								)
							)}
						</Cell>
					</Grid>

					<Grid
						margin={false}
						padding={false}
						className="my-8 pb-8 justify-end"
					>
						<Cell span={12} desktop={6}>
							{total?.amountArticleGrossPriceFormatted && (
								<div className="flex justify-between items-baseline">
									<p className="text-p font-bold inline-block">
										{t('cartpage/amountarticlegrossprice')}
									</p>
									<p className="text-h3 text-blue my-2 md:m-0">
										{`${total?.amountArticleGrossPriceFormatted} ${total?.currency}`}
									</p>
								</div>
							)}
							{total?.amountWebShopDiscountFormatted && (
								<div className="flex justify-between items-baseline">
									<p className="text-p">{t('cartpage/webshopdiscount')}</p>
									<p className="text-p">
										{`- ${total.amountWebShopDiscountFormatted} ${total?.currency}`}
									</p>
								</div>
							)}
						</Cell>
					</Grid>

					{total?.amountArticleNetPriceFormatted && (
						<Grid
							margin={false}
							padding={false}
							className="my-8 pb-8 justify-end"
						>
							<Cell span={12} desktop={6}>
								{total?.amountArticleNetPriceFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p font-bold inline-block">
											{t('cartpage/amountarticlenetprice')}
										</p>
										<p className="text-h3 text-blue my-2 md:m-0">
											{`
											${total?.amountArticleNetPriceFormatted} 
										 ${total?.currency}`}
										</p>
									</div>
								)}
								{total?.shippingCostFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p inline-block">
											{t('cartpage/shippingcost')}*
										</p>
										<p className="text-p">
											{`
											${total?.shippingCostFormatted}
										 ${total?.currency}`}
										</p>
									</div>
								)}
								{total?.administrationCostFormatted && (
									<div className="flex justify-between items-baseline">
										<p className="text-p inline-block">
											{t('cartpage/administrationcost')}**
										</p>
										<p className="text-p">
											{`
											${total?.administrationCostFormatted} 
										 ${total?.currency}`}
										</p>
									</div>
								)}

								<Grid
									margin={false}
									padding={false}
									className="mt-4 flex flex-col"
								>
									{total?.freeShippingLimitFormatted && (
										<p className="text-sm">
											*{' '}
											{`${t('cartpage/freeshippinglimit')}
											${total?.freeShippingLimitFormatted} 
										 ${total?.currency}`}
										</p>
									)}
									{total?.freeAdministrationLimitFormatted && (
										<p className="text-sm">
											**
											{`${t('cartpage/freeadministrationlimit')}
											${total?.freeAdministrationLimitFormatted} 
										 ${total?.currency}`}
										</p>
									)}
								</Grid>
							</Cell>
						</Grid>
					)}

					{(total?.amountArticleNetPriceFormatted ||
						total?.amountArticleGrossPriceFormatted ||
						total?.amountWebShopDiscountFormatted) && (
						<div className="border-t border-greyDarker" />
					)}

					<Grid margin={false} padding={false} className="my-8">
						<Cell span={12}>
							<h2 className="text-h4 text-blue font-light mb-4">
								{t('checkoutpage/orderconfirmation')}
							</h2>
						</Cell>
						<Cell span={12} desktop={6} className="flex">
							<label className="flex items-baseline cursor-pointer relative">
								<input
									type="checkbox"
									id="agreementsConfirmation"
									className="relative opacity-0"
									onChange={() => setAgreementsConfirmed(!agreementsConfirmed)}
									onKeyDown={handleKeyDown}
								/>
								<Icon
									icon={agreementsConfirmed ? 'checkSquare' : 'square'}
									size={1.25}
									className={clsx(
										'absolute left-0 focused-sibling:outline-black ',
										agreementsConfirmed ? 'text-blue' : 'text-greyDarkest'
									)}
									aria-hidden={true}
								/>
								<span className="ml-4 text-p">
									<RichText boldText text={disclaimerText} />
								</span>
							</label>
						</Cell>
						<Cell span={12} desktop={6}>
							{total?.amountNetPriceFormatted && (
								<div className="flex justify-between items-baseline">
									<p className="text-p font-bold inline-block">
										{t('cartpage/total')}
									</p>
									<p className="text-h3 md:text-h2-large text-blue font-normal my-2 md:m-0">
										{`
											${total?.amountNetPriceFormatted} 
										 ${total?.currency}`}
									</p>
								</div>
							)}
							{total?.amountGrossPriceFormatted && (
								<div className="flex justify-between items-baseline">
									<p className="text-p">{t('cartpage/grosstotal')}</p>
									<p className="text-p">
										{`${total?.amountGrossPriceFormatted} ${total?.currency}`}
									</p>
								</div>
							)}

							{checkoutErrorMessage && (
								<p className="text-red text-p mt-2" aria-live="polite">
									{checkoutErrorMessage}
								</p>
							)}
							<div className="flex justify-end my-8 flex-wrap items-baseline">
								{saveCartButton && (
									<Button
										type="button"
										className="flex justify-end ml-4 mt-2 mb-3 md:mt-0 whitespace-no-wrap "
										buttonColor="blue"
										disabled={!hasArticles}
										onClick={(e) => {
											e.preventDefault();
											setSaveCartModalOpen(true);
										}}
									>
										{saveCartButton.text}
									</Button>
								)}
								{confirmButton && (
									<Button
										type="submit"
										className="flex justify-end ml-4 mt-2 mb-3 md:mt-0 whitespace-no-wrap "
										buttonColor="green"
										onClick={(e) => {
											e.preventDefault();
											handleCheckout();
										}}
										disabled={!agreementsConfirmed || cartLoading}
									>
										{confirmButton.text}
										<Icon icon="check" size={2} aria-hidden="true" />
									</Button>
								)}
								{backToCartButton && (
									<Button
										url={backToCartButton.link}
										type="link"
										buttonColor="grey"
										className="mb-3 ml-2 mt-2"
									>
										<Icon
											icon="chevrons"
											size={2}
											aria-hidden="true"
											className="mr-2 pt-1"
										/>
										{backToCartButton.text}
									</Button>
								)}
							</div>
						</Cell>
					</Grid>
				</Layout>
			</main>
			{saveCartModalOpen && (
				<SaveCartModal
					onToggle={() => setSaveCartModalOpen(false)}
					onSave={handleSaveCart}
				/>
			)}
			{overwriteCartModal.isActive && (
				<AlertModal
					title={t('cartpage/overwritecart')}
					heading={t('cartpage/overwritecart')}
					message={overwriteCartModal.message}
					cancelButtonText={t('shared/general/cancel')}
					actionButtonText={t('cartpage/overwrite')}
					onToggle={() =>
						setOverwriteCartModal({ ...overwriteCartModal, isActive: false })
					}
					onClick={handleOverwriteCart}
				/>
			)}
			{articleModal.isActive && (
				<ArticleDetail
					onToggle={() => setArticleModal({ link: null, isActive: false })}
					articleApiUrl={articleModal.link}
					disableControls
					activeMarket={framework.header.activeMarket}
				/>
			)}
			{goodsmarkModalOpen && (
				<AddGoodsmarkModal
					onToggle={() => setGoodsmarkModalOpen(false)}
					handleAddGoodsmark={handleSelectedGoodsmark}
					warningMessage={t('shared/goodsmark/warningmessage')}
					maxLength={cartObject?.limitation.articleGoodsMarkingLength}
				/>
			)}
			{clearGoodsmarkModalOpen && (
				<AlertModal
					title={t('shared/goodsmark/deletegoodsmark')}
					heading={t('shared/goodsmark/deletegoodsmark')}
					message={t('shared/goodsmark/deletegoodsmarkdialogmessage')}
					cancelButtonText={t('shared/general/cancel')}
					actionButtonText={t('shared/goodsmark/deletegoodsmark')}
					onToggle={() => setClearGoodsmarkModalOpen(false)}
					onClick={handleSelectedGoodsmark}
				/>
			)}
			{reloadPricesModal.isActive && (
				<AlertModal
					title={t('cartpage/reloadpricedialogtitle')}
					heading={t('cartpage/reloadpricedialogtitle')}
					message={reloadPricesModal.message}
					cancelButtonText={t('cartpage/deletedialogcancel')}
					actionButtonText={
						reloadPricesModal.hasReloadButton && t('cartpage/reload')
					}
					onToggle={() =>
						setReloadPricesModal({ ...reloadPricesModal, isActive: false })
					}
					onClick={reloadPricesModal.hasReloadButton && handleReloadPrices}
				/>
			)}
		</>
	);
};

export default CheckoutPage;
